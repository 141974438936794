<template>
  <v-app-bar
    id="app-drawer"
    app
    color="#3D3D3D"
    flat
    dark
    height="70"
  >
    <v-toolbar-title class="font-weight-light align-self-center">
      <v-btn
        icon
        @click.stop="onClick"
      >
        <v-icon>mdi-view-list</v-icon>
      </v-btn>
    </v-toolbar-title>
    <v-divider
      dark
      class="mx-4"
      inset
      vertical
    />
    <div>
      <v-img
        src="@/assets/radarmeteo-logotipo.png"
        width="280"
      />
    </div>
    <v-spacer />
    <v-toolbar-items>
      <v-row
        align="center"
        class="mx-0"
      >
        <v-chip
          class="ma-2"
          color="tertiary"
          text-color="white"
        >
          <v-avatar left>
            <v-icon>mdi-account-circle</v-icon>
          </v-avatar>Welcome, {{ username }}!
        </v-chip>
        <v-btn
          icon
          @click="signOut"
        >
          <v-icon>mdi-logout</v-icon>
        </v-btn>
      </v-row>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import * as api from '@/api/api.js';

export default {
  computed: {
    ...mapState(['username', 'drawer'])
  },
  methods: {
    ...mapMutations(['setDrawer', 'toggleDrawer']),
    onClick() {
      this.setDrawer(!this.drawer);
    },
    signOut() {
      api.logOff();
      this.$router.push('/');
      location.reload();
    }
  }
};
</script>
